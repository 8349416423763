import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isOnline from 'is-online';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Subject, filter, fromEvent } from 'rxjs';
import { SharedDataService } from './shared-data.service';
import { environment } from 'src/environments/environment';
import { AgencyService } from './agency.service';
import { RetellService } from './retell.service';
import { ToasterService } from './toastr.service';
@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  user: any = JSON.parse(localStorage.getItem('user')!);
  websocketOpened: boolean = false;
  internetConnectionLost = true;
  webSocket!: WebSocket;
  webSocketRoom!: WebSocket;
  access!: string;
  networkStatus: boolean = false;
  newConversations: any = [];
  chatMessages: any = [];
  websocketConenctionCount: number = 0;
  pingPongResponse = false;

  isLoading: boolean = false;

  callEvents = new Subject<any>();
  userStatus = new BehaviorSubject<any>([]);
  campaignStatus = new BehaviorSubject<any>([]);
  campaignProgress = new BehaviorSubject<any>([]);
  audioStream = new BehaviorSubject<any>([]);
  askCopilotEvent = new BehaviorSubject<any>([]);
  integrationEvents = new Subject<any>();

  testAgentTextEvents = new Subject<any>();
  subscriptionEvent = new Subject<any>();

  constructor(
    private router: Router, 
    private sharedService: SharedDataService,
    public agencyService: AgencyService,
    private _toastr: ToasterService,
  ) {
    this.checkInternetConnection();
    let temp = localStorage.getItem('token')?.substr(4);
    //console.log(temp);
    if (temp) {
      //   this.access = temp[1];
      this.access = temp;
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        const message = event.data;
        // Handle the message from the service worker
        console.log('Received message from service worker:', message);
        // Perform actions based on the message
        if(sharedService.isLoggedIn) {
          if(message.type === 'websocket') this.openWebSocket();
          else if(message.type === 'notification_sound') this.playNotificationSound();
        }
      });      
    }
  }

  setInternetConnection(status: boolean) {
    if (status) {
      this.internetConnectionLost = false;
    } else {
      this.internetConnectionLost = true;
    }
  }
  private connectWebhook!: () => void;

  //TODO Use behaviour subject for websocket messages
  public openWebSocket() {
    if (!this.websocketOpened && !this.internetConnectionLost && !this.isLoading) {
      this.isLoading = true;
      let token = sessionStorage.getItem('client_token')?.substring(4);
      if(!token) token = localStorage.getItem('token')?.substring(4)
      this.webSocket = new WebSocket(
        (environment.https ? 'wss' : 'ws')+'://'+environment.API_URL+'/ws/chat?token=' + token          
      );

      this.webSocket.onerror = (event) => {
        console.log('Error:', event);
        this.websocketOpened = false;
        this.isLoading = false;
      };

      this.webSocket.onopen = (event) => {
        console.log('Open: ', event);
        this.websocketConenctionCount = 1;
        this.websocketOpened = true;
        this.pingPongResponse = true;
        this.isLoading = false;
      };

      this.webSocket.onmessage = (event) => {
        let temp = JSON.parse(event.data);
        console.log(temp);
        if (temp.type === 'pong') {
          this.pingPongResponse = true;
        } else if(temp.type === 'webhook_status'){
          this.connectWebhook();
         } 
        else if(temp.type === 'prompt_gen'){
          console.log(temp)
          this.askCopilotEvent.next(temp.message);
        } else if(temp.type === 'call') {
          console.log(temp)
          this.callEvents.next(temp.data);
        } else if (temp.type === 'integration') {
          this.integrationEvents.next(temp.data);
        } else if(temp.type === 'subscription_update')  {
          this._toastr.success(temp.data.message);
          this.subscriptionEvent.next(temp.data);
        } else if(temp.type === 'test_agent')  {
          this.testAgentTextEvents.next(temp);
        }
      };

      this.webSocket.onclose = (event) => {
        console.log(event);
        this.websocketOpened = false;
        this.pingPongResponse = false;
        this.isLoading = false;
        if('serviceWorker' in navigator){
          if(navigator.serviceWorker.controller){
            navigator.serviceWorker.controller.postMessage({ 
              action: 'custom-action', 
              payload: {
                type: 'websocket',
                connected: false,
              },
            });
          }
        }
      };
    }
  }

  onWebhookConnect(fn: () => void) {
    this.connectWebhook = fn;
  }

  //TODO Destroy websocket object on close
  public closeWebSocket() {
    if(this.webSocket) {
      this.webSocket.close();
    }
  }

  public sendMessage(msg: any) {
    if(this.websocketOpened) this.webSocket.send(JSON.stringify(msg));
    else {
      this.openWebSocket();
      setTimeout(() => {
        if(this.websocketOpened) this.webSocket.send(JSON.stringify(msg));
      })
    }
  }

  websocketPingPong() {
    if (this.pingPongResponse) {
      let input = {
        type: 'ping',
      };
      this.webSocket.send(JSON.stringify(input));
      this.pingPongResponse = false;
    } else {
      this.websocketOpened = false;
      this.closeWebSocket();
      this.connectInternet();
    }
  }
  async checkInternetConnection() {
    if (await isOnline()) {
      console.log('internet present');
      this.internetConnectionLost = false;
    } else {
      console.log('Internet lost');
      this.internetConnectionLost = true;
    }
  }

  async connectInternet() {
    if (await isOnline()) {
      this.internetConnectionLost = false;
      this.openWebSocket();
      this.websocketOpened = true;
    } else {
      this.internetConnectionLost = true;
    }
  }

  showNotification(newMessage: any) {
    const notification = new Notification(newMessage?.customer?.name, { 
      body: newMessage?.message?.message ? newMessage?.message?.message : newMessage?.message?.fb_msg_type,
      icon: this.agencyService.favicon,
    });
    this.playNotificationSound();
  }
  
  playNotificationSound() {
    console.log("Playing sound")
    let audio = new Audio('/assets/web_notification.mp3');
    audio.load();
    audio.play();
  }
}
