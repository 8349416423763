import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionPlan } from 'src/app/models/subscription_plan';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrl: './subscription-plan.component.css'
})
export class SubscriptionPlanComponent implements OnInit {

  constructor(
    public subscriptionService: SubscriptionService,
  ) {
    
  }

  @Input() plan!: SubscriptionPlan;
  @Input() isMonthly: boolean = true;
  @Input() loader: boolean = false;
  @Input() numberAddOnDetails!: SubscriptionPlan;
  @Input() aiAgentAddOnDetails!: SubscriptionPlan;
  @Input() userAddOnDetails!: SubscriptionPlan;
  @Input() infobaseAddOnDetails!: SubscriptionPlan;
  @Input() concurrentAddOnDetails!: SubscriptionPlan;
  @Output() planBuy = new EventEmitter<{plan: SubscriptionPlan, duration: string}>();

  ngOnInit(): void {
    console.log(this.subscriptionService?.currentPlan?.id)
  }

  buyPlan() {
    this.planBuy.emit({
      plan: this.plan,
      duration: this.isMonthly ? 'monthly' : 'yearly',
    });
  }
}
