import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AiAgent } from 'src/app/models/ai-agent';
import { AgencyService } from 'src/app/services/agency.service';
import { AgentService } from 'src/app/services/agent.service';
import { CommonService } from 'src/app/services/common.service';
import { RetellService } from 'src/app/services/retell.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'getting-started-steps',
  templateUrl: './getting-started-steps.component.html',
  styleUrl: './getting-started-steps.component.css'
})
export class GettingStartedStepsComponent {

  constructor(
    public sharedDataService: SharedDataService,
    private _router: Router,
    private _commonService: CommonService,
    private _agentService: AgentService,
    private _retellService: RetellService,
    private _toastr: ToasterService,
    public agencyService: AgencyService
  ) {
    
  }

  @Output() close = new EventEmitter<boolean>();
  loaderCall:boolean = false;
  agent!: AiAgent;

  ngOnInit(): void {
    this._agentService.getAllAgents(1).subscribe({
      next: resp => {
        if(resp.body.data?.length > 0) {
          this.agent = new AiAgent(resp.body.data[0])
        }
      }, error: error => {
        console.log(error);
      }
    })
  }

  createAgent() {
    this._router.navigate(['/agents']);
    this.sharedDataService.gettingStartedEnabled = true;
  }

  testCall() {
    this.loaderCall = true;
    let input = {
      agent_id: this.agent?.id,
      is_test_call: true,
    }
    this._retellService.liveAgent = this.agent;
    this._retellService.callLive = true;
    this._retellService.registerCall(input).subscribe({
      next: resp => {
        this.loaderCall = false;
        this._retellService.liveAgent = this.agent;
        this._retellService.startCall(resp.body.data.call_id,resp.body.data.accessToken);
        this.sharedDataService.business.is_agent_test = true;
        this.sharedDataService.setBusiness(this.sharedDataService.business);
        this.close.emit(true);
      }, error: error => {
        console.log(error)
        this._toastr.error(error);
        this.loaderCall = false;
        this._retellService.callLive = false;
      }
    });
  }

  createWidget() {
    this._router.navigate(['/settings/widgets']);
    this.sharedDataService.gettingStartedEnabled = true;
  }

  addNumber() {
    this._router.navigate(['/settings/phone-numbers']);
    this.sharedDataService.gettingStartedEnabled = true;
  }

}
