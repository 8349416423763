import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ddmmyyyy',
})
export class DatePipe implements PipeTransform {
  transform(date: Date | null | undefined | string): string {
    if(date == null || date == undefined) return '';
    if(date === '') return '';
    else {
      let newDate = new Date(date).toLocaleDateString('en',{ year: 'numeric', month: 'short', day: 'numeric' })
      return newDate;
    }
  }
}
