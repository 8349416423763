<div class="main-container" *ngIf="plansScreen">
  <div class="expiry-header">
    <button hlmBtn variant='ghost' *ngIf="!isOnboarding" (click)="plansScreen = false">
      <mat-icon style="width: 16px;margin-right: 8px;" svgIcon="arrow_back"></mat-icon>
      Back
    </button>
    <button hlmBtn variant='ghost' (click)="logout()">Logout</button>
  </div>
  <div class="agent-details">
    <div class="card-fields" style="align-items: center;">
      <span class="heading-subscription">Choose the plan that’s right for you</span>
      <span class="subheading">Flexible Pricing to Fit Your Unique Needs</span>
    </div>
    <hlm-tabs tab='monthly' class='block mx-auto'>
      <hlm-tabs-list style="width: fit-content;margin: 0 calc(50% - 160px);min-width: 180px;" class='w-full grid grid-cols-2' aria-label='tabs example'>
        <button hlmTabsTrigger='monthly'>Monthly</button>
        <button hlmTabsTrigger='annually'>
          <div class="tab-percentage">
            Annually 
            <span *ngIf="agencyService.brand.toLowerCase() !== 'oneinbox'">Save {{percentage}}%</span>
            <span *ngIf="agencyService.brand.toLowerCase() === 'oneinbox'">Save 30%</span>
          </div>
        </button>
      </hlm-tabs-list>
      <div hlmTabsContent='monthly'>
        <div class="plans-list" *ngIf="subscriptionService.plans.length > 0">
          <subscription-plan
            style="flex: 1;"
            *ngFor="let plan of subscriptionService.plans; let i = index"
            [plan]="plan"
            [isMonthly]="true"
            (planBuy)="buyPlan($event.plan, $event.duration)"
            [loader]="loaderBuyPlan === plan?.id"
            [aiAgentAddOnDetails]="aiAgentAddOnDetails"
            [numberAddOnDetails]="numberAddOnDetails"
            [userAddOnDetails]="userAddOnDetails"
            [concurrentAddOnDetails]="concurrentAddOnDetails"
            [infobaseAddOnDetails]="infobaseAddOnDetails"
          ></subscription-plan>
        </div>
        <div class="plans-list" *ngIf="subscriptionService.plans.length === 0">
          <ng-container *ngFor="let card of [1,2,3]">
            <div class="loader-plan-card">
              <span class="skeleton-loader"><ngx-skeleton-loader count="10" appearance="line"></ngx-skeleton-loader></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div hlmTabsContent='annually'>
        <div class="plans-list">
          <subscription-plan
            style="flex: 1;"
            *ngFor="let plan of subscriptionService.plans; let i = index"
            [plan]="plan"
            [isMonthly]="false"
            (planBuy)="buyPlan($event.plan, $event.duration)"
            [loader]="loaderBuyPlan === plan?.id"
            [aiAgentAddOnDetails]="aiAgentAddOnDetails"
            [numberAddOnDetails]="numberAddOnDetails"
            [userAddOnDetails]="userAddOnDetails"
            [concurrentAddOnDetails]="concurrentAddOnDetails"
            [infobaseAddOnDetails]="infobaseAddOnDetails"
          ></subscription-plan>
        </div>
      </div>
    </hlm-tabs>
  </div>
</div>
<div class="main-container" *ngIf="!plansScreen">
  <div class="expiry-header">
    <span></span>
    <button hlmBtn variant='ghost' (click)="logout()">Logout</button>
  </div>
  <div class="expiry-card">
    <img src="../../../../assets/images/subscription_expired.png" alt="">
    <div class="card-fields">
      <span class="expiry-heading">Your subscription has expired!</span>
      <span class="expiry-description">To continue using {{agencyService.brand}}, you need to pick a plan</span>
    </div>
    <div class="renew-option">
      <save-button *ngIf="user?.user_type === 1" [saveButtonText]="'Renew Now'" (saveClicked)="plansScreen = true;"></save-button>
      <span>or</span>
      <cancel-button *ngIf="agencyService.isAgency" [cancelButtonText]="'Contact Us'" (cancelClicked)="mailTo()"></cancel-button>
      <cancel-button *ngIf="!agencyService.isAgency" [cancelButtonText]="'Contact Us'" (cancelClicked)="openIntercom()"></cancel-button>
    </div>
  </div>

</div>

<billing-form *ngIf="billingForm" (close)="billingForm = false" (defaultBilling)="billingAdded($event)"></billing-form>