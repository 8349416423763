import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  walletBalance!: number;
  totalMinutes!: number;
  constructor(private http: HttpClient) { }

  addMoney(obj: any): Observable<any> {
    return this.http.post<any>('/wallet/recharge',obj, {
      observe: 'response'
    })
  }

  validateWalletRecharge(obj: any) :Observable<any> {
    return this.http.put<any>('/wallet/recharge', obj, {
      observe: 'response'
    });
  }

  getWallet(): Observable<any> {
    return this.http.get<any>('/wallet/', { observe: 'response'});
  }

  getSubscriptionWallet(): Observable<any> {
    return this.http.get<any>('/wallet/subscription', { observe: 'response'});
  }

  getWalletTransactions(page: number): Observable<any> {
    return this.http.get<any>('/wallet/transactions?page='+page, { observe: 'response'});
  }

  getAutoRecharge() : Observable<any> {
    return this.http.get<any>('/wallet/auto-recharge', { observe: 'response'});
  }

  addAutoRecharge(obj: any) : Observable<any> {
    return this.http.post<any>('/wallet/auto-recharge', obj, { observe: 'response'});
  }

  disableAutoRecharge() : Observable<any> {
    return this.http.delete<any>('/wallet/auto-recharge', { observe: 'response'});
  }

  addVoiceDetectionRecharge(obj: any): Observable<any> {
    return this.http.post<any>('/wallet/recharge/voice-detection', obj, { observe: 'response' });
  }

  validateVoiceDetectionRecharge(obj: any) :Observable<any> {
    return this.http.put<any>('/wallet/recharge/voice-detection', obj, {
      observe: 'response'
    });
  }
}
