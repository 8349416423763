import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedModule } from '../shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'connect-calendar',
  templateUrl: './connect-calendar.component.html',
  styleUrl: './connect-calendar.component.css'
})
export class ConnectCalendarComponent implements OnInit{

  @Output() close = new EventEmitter<boolean>();
  stepCount: number = 0;
  isLoading: boolean = false;
  isOpen: boolean = true;

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _toastr: ToasterService,
  ) {
    
  }

  ngOnInit(): void {
    
  }

  goToIntegrations() {
    this._router.navigate(['/settings/integrations']);
  }

}
