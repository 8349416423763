import { Injectable } from '@angular/core';
import { RetellWebClient } from 'retell-client-js-sdk';
import Retell from 'retell-client-js-sdk';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AiAgent } from '../models/ai-agent';

const sdk = new RetellWebClient();
@Injectable({
  providedIn: 'root'
})
export class RetellService {
  private retellClient: any;
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];

  retellWebsocket!: WebSocket;

  stream!: MediaStream;
  audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

  liveAgent!: AiAgent;
  callLive: boolean = false;
  callLiveEvents = new Subject<any>();

  outgoingCallTrigger = new BehaviorSubject<any>([]);

  callIdSet: Set<string> = new Set<string>();
  liveCallTranscript = new Subject<any>();
  
  constructor(private http: HttpClient) {
    // this.initializeRetell();
    // websocketService.audioStream.subscribe((data) => {
    //   // var buffer = new Uint8Array( data.length );
    //   // buffer.set( new Uint8Array(data), 0 );
    //   const arrayBuffer = this.stringToArrayBuffer(data);
    //   console.log(arrayBuffer);
    //   this.playAudio(arrayBuffer);
    //   // const base64String = data;
    //   // const byteArray = this.base64ToUint8Array(base64String);
    //   // var convertedByteString= this.stringToArrayBuffer(data);
    //   // console.log(typeof convertedByteString);
    //   // console.log(convertedByteString);

    //   // // var buffer = this.byteStringToUint8Array( data );
    //   // // buffer.set( new Uint8Array(bytes), 0 );

    //   // // context.decodeAudioData(buffer.buffer, play);

    //   // // Decode and play the audio data
    //   // this.audioContext.decodeAudioData(convertedByteString, buffer => {
    //   //   const source = this.audioContext.createBufferSource();
    //   //   source.buffer = buffer;
    //   //   source.connect(this.audioContext.destination);
    //   //   source.start(0);
    //   // })
    // })
  }

  byteStringToUint8Array(byteString: string): Uint8Array {
    const len = byteString.length;
    const bytes = new Uint8Array(len);
  
    for (let i = 0; i < len; i++) {
      bytes[i] = byteString.charCodeAt(i);
    }
  
    return bytes;
  }


  startCall(id: string ,token: string) {
    console.log("Call live status: "+ this.callLive);
    sdk.startCall({
      accessToken: token,
    });
    console.log("call started")
    this.callLiveEvents.next(({
      type: 'call_started',
      call_id: id,
    }))
    // navigator.mediaDevices.getUserMedia({ audio: true })
    // .then(stream => {
    //   this.mediaRecorder = new MediaRecorder(stream);
    //   this.mediaRecorder.ondataavailable = (event) => {
    //     this.audioChunks.push(event.data);
    //     console.log(event.data);
    //     // this.sendAudioBytes(event.data);
        
    //     this.stream = stream; 
    //     // this.playAudio(event.data);
    //   };
    //   this.mediaRecorder.start(5000);
    // })
    // .catch(error => console.error('Error accessing microphone:', error));

    sdk.on('ready', () => {
      console.log('Retell SDK is ready.');
    });

    sdk.on('error', (error: any) => {
      console.error('Retell SDK error:', error);
      this.stopCall();
    });

    // Set up additional event listeners
    sdk.on('callStarted', (callInfo: any) => {
      console.log('Call started:', callInfo);
    });

    sdk.on('callEnded', (callInfo: any) => {
      console.log('Call ended:', callInfo);
    });

    sdk.on('event', (event: any) => {
      console.log('Event received:', event);
    });

    sdk.on('update', (event: any) => {
      // console.log('Call update:', event);
      this.liveCallTranscript.next(event.transcript)
    });
    
  }

  // startRecording() {
  //   navigator.mediaDevices.getUserMedia({ audio: true })
  //     .then(stream => {
  //       this.mediaRecorder = new MediaRecorder(stream);
  //       this.mediaRecorder.ondataavailable = (event) => {
  //         this.audioChunks.push(event.data);
  //         console.log(event.data);
  //         // this.sendAudioBytes(event.data);
  //         // this.playAudio(event.data);
  //       };
  //       this.mediaRecorder.start(5000); // Collect audio data in chunks of 1 second
  //     })
  //     .catch(error => console.error('Error accessing microphone:', error));
  // }

  // sendAudioBytes(audioData: Blob) {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const audioBytes = new Uint8Array(reader.result as ArrayBuffer);
  //     this.retellClient.sendAudio(audioBytes);
  //   };
  //   reader.readAsArrayBuffer(audioData);
  // }

  // public stopRecording() {
  //   if (this.mediaRecorder) {
  //     this.mediaRecorder.stop();
  //     this.mediaRecorder = null;
  //   }
  // }

  // playAudio(audioData: Blob) {
  //   const audioUrl = URL.createObjectURL(audioData);
  //   console.log(audioUrl)
  //   const audio = new Audio(audioUrl);
  //   audio.play();
  // }

  getAgentTemplate(): Observable<any> {
    return this.http.get<any>('/voice/templates', { observe: 'response' });
  }

  stopCall() {
    this.callLive = false;
    console.log("Call live status: "+ this.callLive);
    sdk.stopCall();
    // this.stream.getTracks().forEach(track => track.stop());
    // this.stopRecording();
  }

  registerCall(obj: any): Observable<any> {
    return this.http.post<any>('/voice/call/register',obj,  {
      observe: 'response',
    });
  }

  liveTextCall(id: number, obj: any): Observable<any> {
    return this.http.post<any>('/voice/ai-agent/test/text/'+id,obj,  {
      observe: 'response',
    });
  }

  outgoingCall(obj: any) :Observable<any> {
    return this.http.post<any>('/voice/call/outbound', obj, {observe: 'response'});
  }

  // private playAudio(arrayBuffer: ArrayBuffer): void {
  //   const audio = new Uint8Array(arrayBuffer);
  //   this.playingAudio(audio);
  // }

  // private stringToArrayBuffer(str: string): ArrayBuffer {
  //   const encoder = new TextEncoder();
  //   return encoder.encode(str).buffer;
  // }
  // private isAudioWorkletSupported(): boolean {
  //   return (
  //     /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  //   );
  // }

  // private audioNode!: AudioWorkletNode;
  // private audioData: Float32Array[] = [];
  // private playingAudio(audio: Uint8Array): void {
  //   // if (this.isAudioWorkletSupported()) {
  //   //   this.audioNode.port.postMessage(audio);
  //   // } else {
  //   //   // if (!this.isTalking) {
  //   //   //   this.isTalking = true;
  //   //   //   this.emit("agentStartTalking");
  //   //   // }
  //   // }
  //   const float32Data = this.convertUint8ToFloat32(audio);
  //   const audioBuffer = this.audioContext.createBuffer(1, float32Data.length, 8000);
  //   audioBuffer.copyToChannel(float32Data, 0);

  //   const source = this.audioContext.createBufferSource();
  //   source.buffer = audioBuffer;
  //   source.connect(this.audioContext.destination);
  //   source.start(0);
  //   // this.audioData.push(float32Data);
  // }

  convertUint8ToFloat32(array: Uint8Array): Float32Array {
    const targetArray = new Float32Array(array.byteLength / 2);
  
    // A DataView is used to read our 16-bit little-endian samples out of the Uint8Array buffer
    const sourceDataView = new DataView(array.buffer);
  
    // Loop through, get values, and divide by 32,768
    for (let i = 0; i < targetArray.length; i++) {
      targetArray[i] = sourceDataView.getInt16(i * 2, true) / Math.pow(2, 16 - 1);
    }
    return targetArray;
  }
  
  convertFloat32ToUint8(array: Float32Array): Uint8Array {
    const buffer = new ArrayBuffer(array.length * 2);
    const view = new DataView(buffer);
  
    for (let i = 0; i < array.length; i++) {
      const value = (array[i] as number) * 32768;
      view.setInt16(i * 2, value, true); // true for little-endian
    }
  
    return new Uint8Array(buffer);
  }

  // Add methods to interact with the Retell SDK as needed...
}
