import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CallEnum, DisconnectionReasonData, GlobalStrings } from 'src/app/constants';
import { Call, CallLabel } from 'src/app/models/call';
import { CallLog } from 'src/app/models/call-log';
import { CloudNumber } from 'src/app/models/cloud-number';
import { ConversationLabel } from 'src/app/models/conversation';
import { Address, Customer, CustomerLabel } from 'src/app/models/customer';
import { Label } from 'src/app/models/label';
import { LoggedUser } from 'src/app/models/logged-user';
import { Note } from 'src/app/models/note';
import { AgentService } from 'src/app/services/agent.service';
import { ChatsService } from 'src/app/services/chats.service';
import { NotesService } from 'src/app/services/notes.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';
import { VoiceService } from 'src/app/services/voice.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'call-log-details',
  templateUrl: './call-log-details.component.html',
  styleUrl: './call-log-details.component.css'
})
export class CallLogDetailsComponent implements OnInit, OnDestroy {

  isOpen: boolean = false;
  @Input() callLog: any;
  @Output() customerUpdate = new EventEmitter<any>();
  @Output() callUpdate = new EventEmitter<any>();
  @Output() callEnded = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  audioUrl: string = '';
  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  @ViewChild('urlTip') _urlTooltip!: MatTooltip;
  enum = CallEnum;
  ratingList = [false,false,false,false,false];
  isFeedBack: boolean = false;
  feedbackRating!: number;
  customer!: Customer;
  showCustomerProfile: boolean = false;
  currentTab: string = 'overview';
  searchTag: string = '';
  killCallLoader: boolean = false;

  comments: Note[] = [];
  newComment: string = '';
  callUsage: number = 0;

  activeCloudNumber: number = 0;

  actionsList: any[] = [];
  currentCallId!: number;
  loaderCallLog: boolean = false;
  loaderGetRecording: boolean = false;
  private destroy$ = new Subject<void>();
  extractInfoDropdown: boolean = false;

  sentimentHoverInfo = 'Analyzes the tone of the conversation, indicating whether it was neutral, positive, or negative.';
  callStatusHoverInfo = 'Indicates whether the conversation was completed or left incomplete.';
  taskStatusHoverInfo = 'Indicates whether the task discussed in the conversation was completed and resolved.';
  ratingHoverInfo = 'Tell us how the AI did—your feedback helps us get better.';

  disconnectionReasonData = DisconnectionReasonData;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _agentService: AgentService,
    private _voiceService: VoiceService,
    private _toastr: ToasterService,
    public constants: GlobalStrings,
    private _usersService: UsersService, 
    private cdr: ChangeDetectorRef,
    public sharedDataService: SharedDataService,
    private _notesService: NotesService,
    private _websocketService: WebSocketService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'copy_light',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/copy_light.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'incoming_call',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/incoming_call.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'outgoing_call',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/outgoing_call.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'star_fill',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/star_fill.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'star_empty',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/star_empty.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'x',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/x.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'ongoing_call',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/ongoing_call.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/person.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/phone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'ringing',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/ringing.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'call_end',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/call_end.svg'
      )
    );
  }

  ngOnInit(): void {
    this.isOpen = true;
    this.ratingList = [false,false,false,false,false];
    if(this.callLog?.ratings) {
      if(this.callLog?.ratings?.rating > 0) this.ratingList[0] = true;
      if(this.callLog?.ratings?.rating > 1) this.ratingList[1] = true;
      if(this.callLog?.ratings?.rating > 2) this.ratingList[2] = true;
      if(this.callLog?.ratings?.rating > 3) this.ratingList[3] = true;
      if(this.callLog?.ratings?.rating > 4) this.ratingList[4] = true;
    }

    if(this.currentCallId !== this.callLog?.id) {
      this.currentCallId = this.callLog?.id;
      this.currentTab = 'overview';
      this.audioUrl = '';
      if(this.callLog?.id && this.callLog?.recording_url) {
        this.audioUrl = this.callLog?.recording_url;
      } else this.audioUrl = '';
      if(this.callLog?.id) this.getSingleCallLog(this.callLog?.id);
      if(this.callLog?.customer) {
        this._usersService.getSingleUser(this.callLog?.customer?.id).subscribe({
          next: resp => {
            this.customer = new Customer(resp.body.data);
            console.log(this.customer)
          }, error: error => {
            this._toastr.error(error);
          }
        })
      } else {
        this.customer = new Customer(null);
      }
      this._notesService.getNotes({call: this.callLog?.id}).subscribe({
        next: resp => {
          console.log(resp);
          let responseList = resp.body.data;
          this.comments = [];
          responseList?.forEach((element: any) => {
            this.comments.push(new Note(element));
          })
          this.comments.reverse();
        }, error: error => {
          console.log(error);
          this._toastr.error(error);
        },
      })
    }

    if(this.callLog?.cost) {
      this.callUsage = Math.ceil(this.callLog?.cost / 60);

    }
    else if(this.callLog?.duration) {
      this.callUsage = Math.ceil(this.callLog?.duration / 60);
    }

    this.sharedDataService.cloudNumbers?.forEach((element) => {
      if(element?.is_active) this.activeCloudNumber++;
    })

    this._websocketService.callEvents
    .pipe(takeUntil(this.destroy$))
    .subscribe((event) => {
      if(this.callLog?.id === event?.call_log_id) {
        this.callLog.call_status = event?.call_status;
        if(event?.call_status === 'ended') {
          this.getSingleCallLog(event?.call_log_id);
        }
      }
    });

    // this._voiceService.getAssignLabel(this.callLog?.voice_inbox?.id).subscribe({
    //   next: resp => {
    //     this._toastr.success(resp.body.message);
    //   }, error: error => {
    //     this._toastr.error(error);
    //   }
    // })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  copyMessage(){
    let copyString = '';
    this.callLog?.transcript_object?.forEach((element:any) => {
      copyString += element?.role + ': '+ element?.content+ ' \n ';
    })
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = copyString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      this._urlTooltip.show();
      this._urlTooltip.message = "Copied!";
    });
  }

  rateCall(rate: number, reason: string = '') {
    if(((rate+1) < 3) && !this.isFeedBack) {
      this.isFeedBack = true;
      this.feedbackRating = rate;
      return ;
    }
    this.isFeedBack = false;
    let input: Record<string,any> = {
      call: this.callLog?.id,
      rating: rate+ 1,
    }
    if(reason !== '') {
      input['reason'] = reason;
    }
    if((rate+1) > 0) this.ratingList[0] = true;
    else this.ratingList[0] = false;
    if((rate+1) > 1) this.ratingList[1] = true;
    else this.ratingList[1] = false;
    if((rate+1) > 2) this.ratingList[2] = true;
    else this.ratingList[2] = false;
    if((rate+1) > 3) this.ratingList[3] = true;
    else this.ratingList[3] = false;
    if((rate+1) > 4) this.ratingList[4] = true;
    else this.ratingList[4] = false;
    this._voiceService.rateCallLog(input).subscribe({
      next: resp => {
        this._toastr.success(resp.body.message);
        this.callLog.ratings = {
          id: this.callLog?.ratings?.id,
          rating: input['rating'],
          reason: this.callLog?.ratings?.reason,
          created_at: this.callLog?.ratings?.created_at,
          call: this.callLog?.ratings?.call
        }
      }, error: error => {
        this._toastr.error(error)
      }
    })
  }

  updateCustomer(customer: Customer) {
    this.customer = new Customer(customer);
    this.showCustomerProfile = false;
    this.customerUpdate.emit({
      id: customer.id,
      name: customer.name,
      mobile: customer?.mobile,
    })
  }

  getSingleCallLog(id: number) {
    if(!this.loaderCallLog) {
      this.loaderCallLog = true;
      let input = {
        id: id
      }
      this._voiceService.getSingleCallLog(input).subscribe({
        next: resp => {
          this.loaderCallLog = false;
          let call = resp.body.data;
          this.updateCallLog(call);
        }, error: error => {
          console.log(error);
          this.loaderCallLog = false;
        }
      })
    }
  }

  updateCallLog(call: any) {
    if(call?.call_status === 'registered' && this.timeDifferenceOf5Mins(call?.created_at)) call.call_status = 'ended';
    this.callLog = call;
    this.audioUrl = this.callLog?.recording_url;
    if(this.callLog?.ratings) {
      if(this.callLog?.ratings?.rating > 0) this.ratingList[0] = true;
      if(this.callLog?.ratings?.rating > 1) this.ratingList[1] = true;
      if(this.callLog?.ratings?.rating > 2) this.ratingList[2] = true;
      if(this.callLog?.ratings?.rating > 3) this.ratingList[3] = true;
      if(this.callLog?.ratings?.rating > 4) this.ratingList[4] = true;
    }
    this.actionsList = [];
    this.callLog?.actions?.forEach((element: any) => {
      this.actionsList.push(element);
    })
    this.actionsList.push({
      name: this.callLog?.direction === this.enum.outbound ? 'Outgoing call' : 'Incoming call',
      type: this.enum.inbound,
      timestamp: this.callLog?.start_timestamp,
    })
    this.actionsList.push({
      name: 'Call ended',
      type: 'ended',
      timestamp: this.callLog?.end_timestamp,
    })
    this.actionsList?.forEach((element: any) => {
      console.log(new Date(element?.timestamp));
      element.timestamp = new Date(element?.timestamp);
    })
    this.actionsList.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    if(Object.keys(this.callLog?.call_analysis?.custom_analysis_data)?.length > 0) {
      this.actionsList.push({
        name: 'Information extracted',
        type: 'extract_info',
        timestamp: this.callLog?.end_timestamp,
        data: this.callLog?.call_analysis?.custom_analysis_data,
      })
    }
    this.callUpdate.emit(this.callLog)
  }

  retryRecording() {
    this.loaderGetRecording = true;
    this._voiceService.getCallRecording(this.callLog?.id).subscribe({
      next: resp => {
        console.log(resp);
        let call = resp.body.data;
        this.updateCallLog(call);
        this.loaderGetRecording = false
      }, error: error => {
        console.log(error);
        this.loaderGetRecording = false;
        this._toastr.error(error);
      }
    })
  }

  timeDifferenceOf5Mins(time: any) :boolean {
    const currentTime = new Date(); // Get the current time as a Date object
  
    const targetDateTime = new Date(time); // Parse the target time string
    const targetTimeInMs = targetDateTime.getTime(); // Convert target time to milliseconds
    
    const currentTimeInMs = currentTime.getTime(); // Get current time in milliseconds
    
    const differenceInMs = currentTimeInMs - targetTimeInMs; // Calculate the difference
    
    const fiveMinutesInMs = 5 * 60 * 1000; // 5 minutes in milliseconds

    if(differenceInMs > fiveMinutesInMs) return true;
    else return false;
  }


  killCall() {
    let input = {
      call_id: this.callLog?.call_id,
    }
    this.killCallLoader = true;
    this._voiceService.killCall(input).subscribe({
      next: resp => {
        console.log(resp);
        this.callLog.call_status = 'canceled';
        this.getSingleCallLog(this.callLog?.id);
        this.killCallLoader = false;
        this.callUpdate.emit(this.callLog);
      }, error: error => {
        console.log(error);
        this._toastr.error(error);
        this.killCallLoader = false;
      }
    })
  }

  deleteComment(comment: Note, index: number) {
    this._notesService.deleteNote(comment?.id).subscribe({
      next: resp => {
        this._toastr.success(resp.body.message);
      }, error: error => {
        this._toastr.error(error);
      }
    })
    this.comments.splice(index,1);
  }

  updateComment(text: string, index: number) {
    let input = {
      note: text,
    }
    console.log(this.comments[index]);
    this._notesService.updateNote(input, this.comments[index]?.id).subscribe({
      next: resp => {
        this._toastr.success(resp.body.message);
      }, error: error => {
        this._toastr.error(error);
      }
    })
    this.comments[index].note = text;
    this.comments[index].added_by.name = this.user?.name;
    this.comments[index].added_by.id = this.user?.id;
    this.comments[index].updated_at = new Date();
  }

  addComment() {
    let input: Record<string,any> = {
      call: this.callLog?.id,
      note: this.newComment,
    }
    this._notesService.addNotes(input).subscribe({
      next: resp => {
        this._toastr.success(resp.body.message);
        if(resp.body.data) {
          let comment = new Note(resp.body.data);
          this.comments.pop();
          this.comments.push(comment);
        }
      }, error: error => {
        this._toastr.error(error);
      }
    })
    input['added_by'] = {
      id: this.user?.id,
      name: this.user?.name,
    }
    input['created_at'] = new Date();
    input['uuid'] = uuidv4()
    this.comments.push(new Note(input));
    this.newComment = '';
  }

  assignLabel(labels: Label[]) {
    let labelIds: number[] = [];
    labels?.forEach((label) => {
      labelIds.push(label.id);
    })
    let input = {
      label_id: labelIds,
      call_id: this.callLog?.id
    }
    this.callLog.labels = labels;
    this._voiceService.assignLabel(this.callLog?.voice_inbox?.id, input).subscribe({
      next: resp => {
        this._toastr.success(resp.body.message);
      }, error: error => {
        this._toastr.error(error);
      }
    })
  }
}
