import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStrings } from 'src/app/constants';
import { Address, Customer, CustomerLabel, CustomProperty } from 'src/app/models/customer';
import { Label } from 'src/app/models/label';
import { LoggedUser } from 'src/app/models/logged-user';
import { Note } from 'src/app/models/note';
import { BusinessService } from 'src/app/services/business.service';
import { ChatsService } from 'src/app/services/chats.service';
import { CommonService } from 'src/app/services/common.service';
import { NotesService } from 'src/app/services/notes.service';
import { RetellService } from 'src/app/services/retell.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { UsersService } from 'src/app/services/users.service';
import { VoiceService } from 'src/app/services/voice.service';

@Component({
  selector: 'customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {

  @Output() close = new EventEmitter<Customer>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() onCustomerAdd = new EventEmitter<Customer>();
  @Input() customer!: Customer;
  
  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));

  editedCustomer!: Customer;
  activeInput: string = '';
  isLoading: boolean = false;
  isOpen: boolean = false;
  notesList: Note[] = [];
  newNote: string = '';
  editingNoteId: number = 0;
  isAddCustomField: boolean = false;
  loaderAddCustomField: boolean = false;
  addCustomFieldDetails = {
    name: '',
    type: '',
  }
  fieldTypeMap: Record<string,string> = {
    'Text' : 'text', 
    'Number' : 'int', 
    'Address' : 'address', 
    'Date' : 'date', 
    'URL' : 'url', 
    'Checkbox' : 'bool', 
  }

  isEditCustomField: boolean = false;
  editingCustomField!: CustomProperty;

  deleteConfirmation: boolean = false;
  deleteHeading: string = '';
  deleteDescription: string = '';
  deleteNegativeButton: string = '';
  deletePositiveButton: string = '';
  deleteType: string = '';

  constructor(
    private _toast: ToasterService,
    private _usersService: UsersService,
    public sharedService: SharedDataService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _retellService: RetellService,
    private _notesService: NotesService,
    private _commonService: CommonService,
    private _businessService: BusinessService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'x',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/x.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/email.svg'
      )
    ); 
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/add.svg'
      )
    ); 
  }

  ngOnInit(): void {
    this.isOpen = true;
    // if(this.usersService.getCustomerData()?.id) {
    //   this.customer = this.usersService.getCustomerData();
    //   this.customer?.labels?.forEach((label)=>{
    //     this.assingedLabelList.push(this.commonService.getLabelFromAudienceLabel(label));
    //   });
    // }
    // else {
    //   this._activatedRoute.paramMap.subscribe((params) => {
    //     console.log(params);
    //     let id = params.get('id') ? Number(params.get('id')) : 0;
    //     if(id) {
    //       this.isLoading = true;
    //       this.usersService.getSingleUser(id).subscribe({
    //         next: resp => {
    //           if(resp.body.code === 200) {
    //             this.customer = new Customer(resp.body.data);
    //             this.customer?.labels?.forEach((label)=>{
    //               this.assingedLabelList.push(this.commonService.getLabelFromAudienceLabel(label));
    //             });
    //             this.isLoading = false;
    //             console.log(this.customer)
    //           } else {
    //             this.isLoading = false;
    //             this._toast.error(resp.body.message);
    //             this.closeProfile();
    //           }
    //         }, error: error => {
    //           this.isLoading = false;
    //           this._toast.error(error);
    //           this.closeProfile();
    //         }
    //       })
    //     } else this.closeProfile();
    //   })
    // }

    if(this.customer?.id) {
      this.editedCustomer = new Customer(this.customer);
  
      this._usersService.getSingleUser(this.customer?.id).subscribe({
        next: resp => {
          if(resp.body.code === 200) {
            this.customer = new Customer(resp.body.data);
            this.editedCustomer = new Customer(resp.body.data)
            console.log(this.customer)
          } else {
            this._toast.error(resp.body.message);
          }
        }, error: error => {
          this._toast.error(error);
        }
      })
      this._notesService.getNotes({contact: this.customer?.id}).subscribe({
        next: resp => {
          console.log(resp);
          let responseList = resp.body.data;
          this.notesList = [];
          responseList?.forEach((element: any) => {
            this.notesList.push(new Note(element));
          })
        }
      })
    } else {
      this.editedCustomer = new Customer(null);
      this.editedCustomer.name = '';
      this.editedCustomer.mobile = '';
      this._businessService.getCustomProperty().subscribe({
        next: resp => {
          console.log(resp);
          let responseList = resp.body.data;
          responseList?.forEach((element: any) => {
            let field: Record<string,any> = {
              field: element?.field,
              label: element?.label,
              datatype: element?.datatype,
            }
            if(element?.datatype === 'text') field['text_value'] = null;
            if(element?.datatype === 'url') field['url_value'] = null;
            if(element?.datatype === 'address') field['address_value'] = null;
            if(element?.datatype === 'int') field['int_value'] = null;
            if(element?.datatype === 'date') field['date_value'] = null;
            if(element?.datatype === 'bool') field['bool_value'] = null;

            this.editedCustomer.custom_property.push(new CustomProperty(field));
          })
         
        }
      })
    }
  }
 
  updateDateProperty(date: any, property: CustomProperty) {
    if(date) {
    this.editedCustomer?.custom_property?.forEach((element) => {
      if(element.field === property?.field) {
          element.date_value = new Date(date);
          let currentTime = new Date();
          element.date_value.setHours(currentTime.getHours());
          element.date_value.setMinutes(currentTime.getMinutes());
          element.date_value.setSeconds(currentTime.getSeconds());
          element.date_value.setMilliseconds(currentTime.getMilliseconds());
          this.updateCustomer();
        }
      })
    }
  }

  updateCustomer() {
    if(this.customer?.id) {
      this.activeInput = '';
      this.isLoading = true;
      let input: Record<string,any> = {}
      if(this.editedCustomer?.name !== '') input['name'] = this.editedCustomer?.name;
      if(this.editedCustomer?.email !== '') input['email'] = this.editedCustomer?.email;
      let customFields: Record<string, any>[] = [];
      this.editedCustomer?.custom_property?.forEach((element) => {
        if(element?.field) {
          let field: Record<string,any> = {
            field: element?.field,
            datatype: element?.datatype,
            label: element?.label,
          }
          if(element?.datatype === 'text') field['text_value'] = element?.text_value;
          if(element?.datatype === 'address') field['address_value'] = element?.address_value;
          if(element?.datatype === 'url') field['url_value'] = element?.url_value;
          if(element?.datatype === 'bool') field['bool_value'] = element?.bool_value;
          if(element?.datatype === 'int') field['int_value'] = Number(element?.int_value) ?? null;
          if(element?.datatype === 'date') {
            if(element?.date_value) {
              element.date_value = new Date(element?.date_value);
              if(element?.date_value?.getFullYear() && (element?.date_value?.getMonth()+1) && element?.date_value?.getDate()){
                field['date_value'] = element?.date_value?.getFullYear()+'-'+(element?.date_value?.getMonth()+1)+'-'+element?.date_value?.getDate();
                // element.date_value = field['date_value'];
                console.log(element.date_value);
              } else field['date_value'] = null;
            } else field['date_value'] = null;
          }
          customFields.push(field);
        }
      })
      input['custom_property'] = customFields;
      this._usersService.updateUser(this.customer.id, input).subscribe({
        next: resp => {
          this._toast.success(resp.body.message);
          this.customer = new Customer(this.editedCustomer);
          // this.customer = new Customer(this.editedCustomer);
          this.isLoading = false;
        }, error: error => {
          console.log(error);
          this._toast.error(error);
        }
      })
    }
  }

  addNote() {
    console.log(this.newNote);
    if(this.newNote === '') {
      this._toast.error('')
      return ;
    }
    let input = {
      contact: this.customer?.id,
      note: this.newNote,
    }
    this._notesService.addNotes(input).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
        if(resp.body.data) {
          this.notesList.pop();
          this.notesList.push(new Note(resp.body.data));
        }
      }
    })
    let note: Record<string,any> = { ...input };
    note['added_by'] = {
      id: this.user?.id,
      name: this.user?.name,
    }
    this.notesList.push(new Note(note));
    this.newNote = '';
  }

  deleteNote(note: Note, index: number) {
    this._notesService.deleteNote(note?.id).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
      }, error: error => {
        this._toast.error(error);
      }
    })
    this.notesList.splice(index,1);

    // let input = {
    //   note: "Something new"
    // }
    // this._notesService.updateNote(input,note?.id).subscribe({
    //   next: resp => {
    //     console.log(resp);
    //   }
    // })
  }

  noteAction(action: string, note: Note, index: number) {
    if(action === 'Delete') this.deleteNote(note, index);
    else this.editingNoteId = note?.id;
  }

  updateNote(text: string, note: Note, index: number) {
    let input = {
      note: text,
    }
    this._notesService.updateNote(input, note?.id).subscribe({
      next: resp => {
        this._toast.success(resp.body.message);
      }, error: error => {
        this._toast.error(error);
      }
    })
    this.editingNoteId = 0;
    this.notesList[index].note = text;
    this.notesList[index].added_by.name = this.user?.name;
    this.notesList[index].added_by.id = this.user?.id;
    this.notesList[index].updated_at = new Date();
  }

  addContact() {
    let mobile = this._commonService.getMobileNumber(this.editedCustomer?.mobile);
    let code = this._commonService.getCountryCodeFromNumber(this.editedCustomer?.mobile);
    if(code === '') {
      this._toast.error("Enter valid phone number with country code");
      return ;
    }
    let input: Record<string, any> = {
      mobile: mobile,
      country_code: code
    }
    this.isLoading = true;
    if(this.editedCustomer?.name !== '') input['name'] = this.editedCustomer?.name;
    if(this.editedCustomer?.email !== '') input['email'] = this.editedCustomer?.email;
    let customFields: Record<string, any>[] = [];
      this.editedCustomer?.custom_property?.forEach((element) => {
        if(element?.field) {
          let field: Record<string,any> = {
            field: element?.field,
            datatype: element?.datatype,
            label: element?.label,
          }
          if(element?.datatype === 'text') field['text_value'] = element?.text_value;
          if(element?.datatype === 'address') field['address_value'] = element?.address_value;
          if(element?.datatype === 'url') field['url_value'] = element?.url_value;
          if(element?.datatype === 'bool') field['bool_value'] = element?.bool_value;
          if(element?.datatype === 'int') field['int_value'] = Number(element?.int_value) ?? null;
          if(element?.datatype === 'date') {
            if(element?.date_value) {
              element.date_value = new Date(element?.date_value);
              if(element?.date_value?.getFullYear() && element?.date_value?.getMonth() && element?.date_value?.getDate()){
                field['date_value'] = element?.date_value?.getFullYear()+'-'+element?.date_value?.getMonth()+'-'+element?.date_value?.getDate();
              } else field['date_value'] = null;
            } else field['date_value'] = null;
          }
          customFields.push(field);
        }
      })
      input['custom_property'] = customFields;
    this._usersService.addUser(input).subscribe({
      next: resp => {
        this._toast.success(resp.body.message);
        this.customer = new Customer(resp.body.data);
        this.editedCustomer = new Customer(resp.body.data);
        this.onCustomerAdd.emit(this.customer);
        this.isLoading = false;
      }, error: error => {
        console.log(error);
        this._toast.error(error);
        this.isLoading = false;
      }
    })
  }

  deleteContactConfirm() {
    this.deleteType = 'contact';
    this.deleteConfirmation = true;
    this.deleteHeading = 'Confirm delete contact';
    this.deleteDescription = 'Deleting the contact <span class="col-pri">'+ this.customer?.mobile+'</span> remove the profile details, but the associated call logs will not be deleted.';
    this.deleteNegativeButton = 'No, keep contact';
    this.deletePositiveButton = 'Yes, delete contact ';
  }

  deleteCustomFieldConfirm() {
    this.deleteType = 'field';
    this.deleteConfirmation = true;
    this.deleteHeading = 'Confirm delete contact property';
    this.deleteDescription = 'Deleting the contact property <span class="col-pri">'+this.editingCustomField?.label+'</span> will remove it from all contacts.';
    this.deleteNegativeButton = 'No, keep property';
    this.deletePositiveButton = 'Yes, delete property';
  }

  deleteContact() {
    this.deleteConfirmation = false;
    this.delete.emit(true);
    this._usersService.deleteUser(this.customer?.id).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
      }, error: error => {
        console.log(error);
        this._toast.error(error);
      }
    })
  }

  selectType(type: string) {
    this.addCustomFieldDetails.type = type;
    setTimeout(() => {
      this.isAddCustomField = true;

    },100)
  }

  addCustomField(name: string) {
    if(name === '') {
      this._toast.error("Enter valid field name");
      return ;
    }
    let input = {
      field: name.replaceAll(' ', '_').toLowerCase(),
      label: name,
      datatype: this.fieldTypeMap[this.addCustomFieldDetails.type],
    }
    this.loaderAddCustomField = true;
    this._businessService.addCustomProperty(input).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
        let customField: Record<string, any> = input;
        customField[input.datatype+'_value'] = null;
        this.editedCustomer.custom_property.push(new CustomProperty(customField));
        this.customer.custom_property.push(new CustomProperty(customField));
        this.isAddCustomField = false;
        this.loaderAddCustomField = false;
      }, error: error => {
        console.log(error);
        this._toast.error(error);
        this.loaderAddCustomField = false;
      }
    })
  }

  closeAddCustomField() {
    this.addCustomFieldDetails.name = '';
    this.addCustomFieldDetails.type = '';
    this.isAddCustomField = false;
  }

  editCustomField(property: CustomProperty) {
    this.editingCustomField = property;
    setTimeout(() => {
      this.isEditCustomField = true;
    },100)
  }

  saveEditCustomField(name: string) {
    console.log(name);
    if(name === '') {
      this._toast.error("Enter valid field name");
      return ;
    }
    let input = {
      field: this.editingCustomField?.field,
      label: name,
      datatype: this.editingCustomField?.datatype,
    }
    this.loaderAddCustomField = true
    this._businessService.updateCustomProperty(input).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
        this.editedCustomer?.custom_property?.forEach((element) => {
          if(element.field === this.editingCustomField?.field) {
            element.label = name;
            element.field = name.toLowerCase().replaceAll(' ','_');
          }
        })
        this.loaderAddCustomField = false
        this.isEditCustomField = false;
      }, error: error => {
        console.log(error);
        this._toast.error(error);
        this.loaderAddCustomField = false;
      }
    })
  }

  deleteCustomField() {
    this.deleteConfirmation = false;
    for(var index=0;index< this.editedCustomer?.custom_property?.length;index++) {
      if(this.editedCustomer?.custom_property[index]?.field === this.editingCustomField?.field) {
        this.editedCustomer?.custom_property?.splice(index,1)
        break;
      }
    }
    this.isEditCustomField = false;
    this._businessService.deleteCustomProperty(this.editingCustomField?.field).subscribe({
      next: resp => {
        console.log(resp);
        this._toast.success(resp.body.message);
      }, error: error => {
        console.log(error);
        this._toast.error(error);
      } 
    })
  }
}
