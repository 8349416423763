<hlm-sheet style="width: 512px;max-width: 512px !important" [state]="isOpen ? 'open' : 'closed'" [side]="'right'" (closed)="close.emit(callLog)">
  <hlm-sheet-content style="overflow: auto;padding: 24px;width: 512px;max-width: 512px !important" *brnSheetContent="let ctx">
    <div class="ver-1rem h-100">
      <div class="container-heading" 
      >
        <span class="hor-8px">
          <icon *ngIf="callLog?.direction==='inbound'" [icon]="'incoming_call'" [iconColor]="'#64748B'" [size]="'16px'"></icon>
          <icon *ngIf="callLog?.direction==='outbound'" [icon]="'outgoing_call'" [iconColor]="'#64748B'" [size]="'16px'"></icon>
          <span class="call-details">
            <span class="call-details-type">
              <ng-container *ngIf="callLog?.widget">
                Widget call
              </ng-container>
              <ng-container *ngIf="callLog?.customer">
                {{(callLog?.customer | name)}}
              </ng-container>
              <ng-container *ngIf="!callLog?.customer && !callLog?.widget">
                Test web call
              </ng-container>
            </span>
          </span>
          <span class="call-details-time">{{callLog?.created_at | ddmmyyyy}}, {{callLog?.created_at | date: 'shortTime'}}</span>
        </span>
      </div>
      <div class="agent-details" *ngIf="callLog?.call_status !== 'no-answer' && callLog?.call_status !== 'busy' && callLog?.call_status !== 'canceled'">
        <audio-stream *ngIf="callLog?.recording_url" [audioUrl]="callLog?.recording_url"></audio-stream>
        <div class="retry-recording" *ngIf="!callLog?.recording_url">
          <span>Oops! Something went wrong</span> <save-button [saveButtonText]="'Retry'" [icon]="'refresh'" (saveClicked)="retryRecording()" [loading]="loaderGetRecording"></save-button>
        </div>
      </div>
      <div class="agent-details" [ngStyle]="{'height': callLog?.recording_url ? '100%' : 'calc(100% - 64px)'}">
        <div class="agent-details-tab">
          <div class="detail-type-tab" [ngClass]="{ active: currentTab === 'overview' }" 
            (click)="currentTab = 'overview'" ><span>Overview</span></div>
          <div class="detail-type-tab" [ngClass]="{ active: currentTab === 'summary' }" 
            (click)="currentTab = 'summary'" ><span>Summary</span></div>
          <div class="detail-type-tab" [ngClass]="{ active: currentTab === 'transcript' }" 
            (click)="currentTab = 'transcript'" ><span>Transcript</span></div>
          <div class="detail-type-tab" [ngClass]="{ active: currentTab === 'activity' }" 
            (click)="currentTab = 'activity'" ><span>Activity</span></div>
          <div class="detail-type-tab" [ngClass]="{ active: currentTab === 'comments' }" 
            (click)="currentTab = 'comments'" ><span>Comments</span></div>
        </div>
        <div class="agent-tab-description" *ngIf="currentTab === 'overview'">
          <div class="card-fields">
            <span class="overview-heading">Call Information</span>
            <div class="call-log-details">
              <div class="call-log-timings" *ngIf="callLog?.customer">
                <span class="heading w-120">Labels</span>
                <span class="subheading" style="width: 300px;transform: translateY(-4px);">
                  <assign-label *ngIf="!loaderCallLog" class="w-100" [assignedLabel]="callLog?.labels" [inbox]="callLog?.voice_inbox?.id" (close)="assignLabel($event)"></assign-label>
                </span>
              </div>
              <div class="call-log-timings" *ngIf="callLog?.from_number">
                <span class="heading w-120">Call from</span>
                <span class="subheading">{{callLog?.from_number | numberFormat}}</span>
              </div>
              <div class="call-log-timings" *ngIf="callLog?.to_number">
                <span class="heading w-120">Call to</span>
                <span class="subheading">{{callLog?.to_number | numberFormat}}</span>
              </div>
              <div class="call-log-timings" *ngIf="callLog?.widget">
                <span class="heading w-120">Widget</span>
                <span class="subheading hor-8px">
                  <img [src]="callLog?.widget?.profile_pic" uiImageLoader alt=""> 
                  <span>{{callLog?.widget?.name}}</span>
                </span>
              </div>
              <div class="call-log-timings" *ngIf="callLog?.call_status !== 'canceled'">
                <span class="heading w-120">AI Agent</span>
                <span class="subheading hor-8px">
                  <img [src]="callLog?.agent?.profile_pic" uiImageLoader alt=""> 
                  <span>{{callLog?.agent?.name}}</span>
                </span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Duration</span>
                <span class="subheading">00:{{(((callLog?.end_timestamp ?? callLog?.start_timestamp) / 1000) - (callLog?.start_timestamp / 1000)) | timer}}</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Usage</span>
                <span class="subheading">00:{{callLog?.cost | timer}}</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Started at</span>
                <span class="subheading"> {{callLog?.created_at | ddmmyyyy}}, {{callLog?.created_at | date: 'mediumTime'}}</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Ended at</span>
                <span class="subheading">{{callLog?.created_at | ddmmyyyy}}, {{callLog?.updated_at | date: 'mediumTime'}}</span>
              </div>
            </div>
          </div>
          <div class="card-fields" *ngIf="callLog?.call_analysis">
            <span class="overview-heading">Call Analysis</span>
            <div class="call-log-details">
              <div class="call-log-timings">
                <span class="heading w-120"><span style="white-space: nowrap;">Rating </span><hover-information [left]="'-28%'" [hoverInfo]="ratingHoverInfo"></hover-information></span>
                <span class="ratings">
                  <ng-container *ngFor="let rating of ratingList; let i = index">
                    <mat-icon *ngIf="!rating" svgIcon="star_empty" (click)="rateCall(i)"></mat-icon>
                    <mat-icon *ngIf="rating" svgIcon="star_fill" (click)="rateCall(i)"></mat-icon>
                  </ng-container>
                </span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120"><span style="white-space: nowrap;">Call sentiment</span></span>
                <div class="subheading hor-8px">
                  <icon *ngIf="callLog?.call_analysis?.user_sentiment?.toLowerCase()==='neutral'" [icon]="'sentiment_neutral'" [iconColor]="'#2563EB'" [size]="'20px'"></icon>
                  <icon *ngIf="callLog?.call_analysis?.user_sentiment?.toLowerCase()==='positive'" [icon]="'sentiment_satisfied'" [iconColor]="'#16A34A'" [size]="'20px'"></icon>
                  <icon *ngIf="callLog?.call_analysis?.user_sentiment?.toLowerCase()==='negative'" [icon]="'sentiment_dissatisfied'" [iconColor]="'#DC2626'" [size]="'20px'"></icon>
                  <span>{{(callLog?.call_analysis?.user_sentiment) | pascalCase }}</span>
                </div>          
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Call status</span>
                <span class="subheading hor-8px"><span [ngClass]="disconnectionReasonData[(callLog?.disconnection_reason)].color"></span> {{ callLog?.disconnection_reason ? disconnectionReasonData[(callLog?.disconnection_reason)].title : ''}}</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">End call reason</span>
                <span class="subheading hor-8px"><span [ngClass]="disconnectionReasonData[(callLog?.disconnection_reason)].color"></span> {{ callLog?.disconnection_reason ? disconnectionReasonData[(callLog?.disconnection_reason)].reason : ''}}</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Task status <hover-information [left]="'-40%'" [hoverInfo]="taskStatusHoverInfo"></hover-information></span>
                <span class="call-status-details">
                  <span class="hor-8px subheading">
                    <span [class]="callLog?.call_analysis?.agent_task_completion_rating"></span>
                    {{callLog?.call_analysis?.agent_task_completion_rating}}
                  </span>
                  <span class="call-status-description">{{callLog?.call_analysis?.agent_task_completion_rating_reason}}</span>
                </span>
              </div>
            </div>
          </div>
          <div class="card-fields" *ngIf="!callLog?.call_analysis">
            <span class="overview-heading">Call Analysis</span>
            <div class="call-log-details">
              <div class="call-log-timings">
                <span class="heading w-120">Rating</span>
                <span class="subheading">-</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Call sentiment</span>
                <span class="subheading">-</span>          
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Call status</span>
                <span class="subheading">-</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">End call reason</span>
                <span class="subheading">-</span>
              </div>
              <div class="call-log-timings">
                <span class="heading w-120">Task status</span>
                <span class="subheading">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="agent-tab-description" *ngIf="currentTab === 'summary'">
          <div class="card-fields">
            <span class="summary-heading">Summary</span>
          </div>
          <div class="transcript-lines" *ngIf="callLog?.call_analysis || callLog?.call_summary">
            <div class="transcript-log">
              <ul>
                <li>{{callLog?.call_analysis?.call_summary ?? callLog?.call_summary}}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="agent-tab-description" style="height: calc(100% - 50px);" *ngIf="currentTab === 'transcript'">
          <div class="card-fields">
            <span class="heading">
              <search-input class="w-100" [searchInput]="searchTag" (searchInputChange)="searchTag = $event"></search-input>
              <mat-icon matTooltip="Copy to clipboard" #urlTip="matTooltip" outlineOnClick
                (click)="copyMessage()" style="width: 20px; cursor: pointer;" svgIcon="copy_light"></mat-icon></span>
          </div>
          <div class="transcript-lines">
            <ng-container *ngFor="let message of callLog?.transcript_object | search: searchTag">
              <div class="msg-body" [ngClass]="message?.role">
                <div class="transcript-messages">
                  <div class="transcript-details" *ngIf="message?.role === 'user'">
                    <!-- <img src="../../../../../assets/images/avatar.png" alt=""> -->
                    <span class="icon-grey">
                      <mat-icon svgIcon="person"></mat-icon>
                    </span>
                    <span class="name" *ngIf="callLog?.customer">{{ customer | name }}</span>
                    <span class="name" *ngIf="!callLog?.customer">{{ user | name }}</span>
                  </div>
                  <span class="col-sec">{{message?.words[0]?.start | timer}}</span>
                  <div class="transcript-details user" *ngIf="message?.role === 'agent'">
                    <!-- <img src="../../../../../assets/images/avatar.png" alt=""> -->
                    <span class="name">{{ callLog?.agent | name }}</span>
                    <span class="icon-grey">
                      <img [src]="callLog?.agent?.profile_pic ?? '../../../../assets/images/avatar.png'" uiImageLoader alt="">
                    </span>
                  </div>
                </div>
                <div class="msg-content">
                    <div [innerHTML]="message?.content | boldItalic"></div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="agent-tab-description" *ngIf="currentTab === 'activity'">
          <div class="activity-tab">
            <ng-container *ngFor="let action of actionsList; let i=index">
              <span *ngIf="i !== 0" class="activity-divider"><hr></span>
              <div class="activity-item" [ngStyle]="{'align-items' : action?.data ? 'flex-start' : 'center'}">
                <span class="icon">
                  <icon *ngIf="action?.type === enum.inbound" [icon]="'ringing'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'send_sms'" [icon]="'send_sms'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'send_email'" [icon]="'email'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'send_webhook'" [icon]="'webhook'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'transfer_call'" [icon]="'forward_call'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'ended'" [icon]="'call_end'" [iconColor]="'#64748B'" [size]="'20px'"></icon>                
                  <icon *ngIf="action?.type === 'extract_info'" [icon]="'info_alert'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                  <icon *ngIf="action?.type === 'calendar'" [icon]="'calendar'" [iconColor]="'#64748B'" [size]="'20px'"></icon>                
                </span>
                <div class="activity-details">
                  <span class="time">{{action?.timestamp | date: 'shortTime'}}</span>
                  <span class="type">{{ action?.name }}</span>
                  <div class="extracted-info" *ngIf="action?.data">
                    <span class="extracted-info-heading" (click)="extractInfoDropdown = !extractInfoDropdown">
                      <icon outlineOnClick *ngIf="!extractInfoDropdown" [icon]="'add_box_solid'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                      <icon outlineOnClick *ngIf="extractInfoDropdown" [icon]="'indeterminate_check_box'" [iconColor]="'#64748B'" [size]="'20px'"></icon>
                      Extracted fields
                    </span>
                    <span class="extracted-info-list" *ngIf="extractInfoDropdown">
                      <span *ngFor="let item of action.data | keyValue">
                        <span>{{item['key']}}: {{item['value']}}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="agent-tab-description" style="height: 100%;" *ngIf="currentTab === 'comments'">
          <div class="comment-list" *ngIf="comments.length > 0">
            <div class="comment-item" [ngStyle]="{'align-items' : comment?.added_by?.id === user?.id ? 'flex-end' : 'flex-start'}" *ngFor="let comment of comments; let i = index">
              <span class="comment-added" [ngStyle]="{'flex-direction': comment?.added_by?.id === user?.id ? 'row-reverse' : 'row'}">
                <name-tag [name]="comment?.added_by?.name ?? ''"></name-tag>
                <span>{{((comment?.updated_at ?? comment?.created_at) | ddmmyyyy)}}, {{((comment?.updated_at ?? comment?.created_at) | date: 'mediumTime')}}</span>
              </span>
              <edit-note [disable]="comment?.added_by?.id !== user?.id" [note]="comment?.note ?? ''" (save)="updateComment($event,i)" (delete)="deleteComment(comment,i)"></edit-note>
            </div> 
          </div>
          <div class="comment-list comment-empty-screen" *ngIf="comments?.length === 0">
            <img src="../../../../assets/images/comments_empty.png" alt="">
            <span>Engage with your team on this call</span>
          </div>
          <div class="add-comment">
            <message-input [rows]="2" [message]="newComment" (textChange)="newComment = $event" (sendClicked)="addComment()"></message-input>
          </div>
        </div>
      </div>
    </div>
  </hlm-sheet-content>
</hlm-sheet>


<call-feedback *ngIf="isFeedBack" (close)="isFeedBack = false"
  (onSuccess)="rateCall(feedbackRating,$event)"></call-feedback>