import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { show } from '@intercom/messenger-js-sdk';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { BillingDetails } from 'src/app/models/billing-details';
import { LoggedUser } from 'src/app/models/logged-user';
import { SubscriptionPlan } from 'src/app/models/subscription_plan';
import { AgencyService } from 'src/app/services/agency.service';
import { CommonService } from 'src/app/services/common.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'subscription-expired',
  templateUrl: './subscription-expired.component.html',
  styleUrl: './subscription-expired.component.css'
})
export class SubscriptionExpiredComponent implements OnInit{

  constructor(
    public subscriptionService: SubscriptionService,
    private _toast: ToasterService,
    public sharedDataService: SharedDataService,
    private _commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public agencyService: AgencyService,
    private _websocketService: WebSocketService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    let keys = sharedDataService.paymentGatewayKeys;
    loadStripe(keys.is_default ? keys.default : keys.stripe).then((stripe) => {
      if(stripe) this.stripe = stripe;
    });
    this.matIconRegistry.addSvgIcon(
      'arrow_back',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/arrow_back.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/check.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'x',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/x.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/add.svg'
      )
    );
  }

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));

  checkoutObject: any;
  percentage!: number;
  stripe!: Stripe;
  loaderBuyPlan: number = 0;
  billingForm: boolean = false;
  plansScreen: boolean = false;
  numberAddOnDetails!: SubscriptionPlan;
  aiAgentAddOnDetails!: SubscriptionPlan;
  userAddOnDetails!: SubscriptionPlan;
  infobaseAddOnDetails!: SubscriptionPlan;
  concurrentAddOnDetails!: SubscriptionPlan;
  loaderGetStripeUrl: boolean = false;
  isOnboarding: boolean = false;

  ngOnInit(): void {
    let currentUrl = this._router.url;
    if(currentUrl.includes('onboarding-plans')) {
      this.isOnboarding = true;
      this.plansScreen = true;
    }
    else this.isOnboarding = false;
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      if(params['order_id']) {
        this._router.navigate(['/welcome']);
      }
    })
    if(this.sharedDataService.business?.subscription?.is_active) this._router.navigate(['/agents']);
    this._websocketService.openWebSocket();
    this.subscriptionService.getPlans().subscribe({
      next: resp => {
        if(resp.body.code === 200){
          let temp = resp.body.data;
          this.subscriptionService.plans = [];
          temp?.forEach((plan: any) => {
            let subscriptionPlan = new SubscriptionPlan(plan)
            this.subscriptionService.plans.push(subscriptionPlan);            
            if(subscriptionPlan.id === this.subscriptionService.getSubscription()?.plan?.id) {
              this.subscriptionService.currentPlan = new SubscriptionPlan(plan);
              this.subscriptionService.planToBuy = new SubscriptionPlan(plan);
            }
          })
          if(this.subscriptionService.plans?.length > 0) {
            let length = this.subscriptionService.plans?.length;
            let diff = ((this.subscriptionService.plans[length - 1]?.monthly_price) - this.subscriptionService.plans[length -1]?.yearly_price)
            this.percentage =  Math.round( (diff / this.subscriptionService.plans[length -1]?.monthly_price)*100)
            console.log(this.percentage)
          }
        }
      }
    });

    this.subscriptionService.getAddOns().subscribe({
      next: resp => {
        console.log(resp);
        let responseList = resp.body.data;
        responseList?.forEach((element: any) => {
          let plan = new SubscriptionPlan(element)
          if(plan?.add_on_type === 'agent') this.aiAgentAddOnDetails = plan;
          if(plan?.add_on_type === 'number') this.numberAddOnDetails = plan;
          if(plan?.add_on_type === 'infobase') this.infobaseAddOnDetails = plan;
          if(plan?.add_on_type === 'concurrent_call') this.concurrentAddOnDetails = plan;
          if(plan?.add_on_type === 'user') this.userAddOnDetails = plan;
        });
      }
    })
    
    this.subscriptionService.getBillingDetails().subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          let responseList = resp.body.data;
          this.subscriptionService.billingDetails = [];
          responseList?.forEach((element: any) => {
            this.subscriptionService.billingDetails.push(new BillingDetails(element));
          });
        }
        console.log(resp);
      }
    });

  }

  buyPlan(plan: SubscriptionPlan, term: string) {
    if(this.sharedDataService.business.partner?.name) {
      if(this.subscriptionService.billingDetails?.length > 0 && this.subscriptionService.billingDetails[0]?.id) {
        this.loaderBuyPlan = plan?.id;
        let input = {
          plan_id: plan?.id,
          billing_id: this.subscriptionService.billingDetails[0]?.id,
          duration: term,
          success_url: this.isOnboarding ? '/payment-processing?order_id=' : '/billing/wallet?order_id=',
          failed_url: '/onboarding-plans',
        };
        this.subscriptionService.createSubscriptionOrder(input).subscribe({
          next: resp => {
            localStorage.setItem('payment_id',(resp.body.data.payment_id))
            let keys = this.sharedDataService.paymentGatewayKeys;
            loadStripe(keys.is_default ? keys.default : keys.stripe).then((stripe) => {
              if(stripe) this.stripe = stripe;
            }).then(() => {
              this.stripe.redirectToCheckout({
                sessionId: resp.body.data.payment_id,
              });
            });
            this.loaderBuyPlan = 0;
          }, error: error => {
            console.log(error);
            this._toast.error(error);
            this.loaderBuyPlan = 0;
          }
        })
      } else {
        this.billingForm = true;
        this.checkoutObject = {
          term: term,
          plan: plan,
        }
      }
    } else {
      this.redirectToStripe(plan, term)
    }
  }

  billingAdded(billing: BillingDetails) {
    this.billingForm = false;
    this.buyPlan(this.checkoutObject?.plan, this.checkoutObject?.term);
  }

  mailTo() {
    window.location.href = 'mailto:'+this.agencyService.supportEmail;
  }

  logout() {
    this._commonService.logout();
  }

  openIntercom() {
    show()
  }

  redirectToStripe(plan: SubscriptionPlan, term: string) {
    this.loaderBuyPlan = plan?.id;
    let input = {
      plan_id: plan?.id,
      billing_id: this.subscriptionService.billingDetails[0]?.id,
      duration: term,
      success_url: this.isOnboarding ? '/payment-processing?order_id=' : '/billing/wallet?order_id=',
      failed_url: '/onboarding-plans',
    };
    this.subscriptionService.buyStripePlan(input).subscribe({
      next: resp => {
        this.loaderBuyPlan = 0
        window.open(resp.body.data.url);
      }, error: error => {
        this._toast.error(error);
        this.loaderBuyPlan = 0;
      }
    })
  }
}
