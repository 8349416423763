import { Injectable } from '@angular/core';
import { Business } from '../models/business';
import { WhatsappDetails } from '../models/whatsapp-details';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Message } from '../models/message';
import { CloudNumber } from '../models/cloud-number';
import { LoggedUser } from '../models/logged-user';
import { SubscriptionPlan } from '../models/subscription_plan';
import { Widget } from '../models/widget';
import { CustomField } from '../models/custom-field';
import { CustomProperty } from '../models/customer';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  public isLoggedIn: boolean = false;
  public inAuthModule: boolean = true;
  public business: Business = new Business(JSON.parse(localStorage.getItem('business')!));
  public whatsappDetails!: WhatsappDetails;
  public isWabaEnabled: boolean = true;
  public newVersionEnabled: boolean = false;
  public isPartnerBusiness: boolean = false;
  public partnerConversationRate!: string;
  public businessCountry = localStorage.getItem('country');

  public apiCountryList: any[] = [];
  public apiStateList: any[] = [];
  public apiCityList: any[] = [];

  public languageList: any[] = []

  public customFields: CustomProperty[] = [];

  //Responsive
  public responsiveMenuBarOpen: boolean = false;
  public hoverActive: boolean = true;
  routeChange = new Subject<string>();
  routeEmitter = this.routeChange.asObservable();

  audioPlayer: boolean = false;
  audioPlayerMessage!: Message;

  public subscriptionDaysLeft!: number;

  public cloudNumbers: CloudNumber[] = [];
  public callTunes: any[] = [];
  public widgetList: Widget[] = [];

  public userChange = new BehaviorSubject<any>([]);

  gst_no: string = '';

  public remoteConfig = {
    frill: false,
    downtime: false,
  }
  public remoteConfigEvents = new BehaviorSubject<any>([]);

  public templateStudioEnabled: boolean = false;
  public templateStudioOpened: boolean = false;

  public voiceList: any[] = [];

  public loggedUser!: LoggedUser;

  paymentGatewayKeys = {
    is_default: true,
    stripe: '',
    default: ''
  }

  public dialerPopup: boolean = false;
  public dialerInboxSelected!: CloudNumber;

  public agencyPlans: SubscriptionPlan[] = [];
  public agencyAddOns: SubscriptionPlan[] = [];

  public gettingStartedEnabled: boolean = false;
  public gettingStartedStepCount: number = 0;

  public importingContacts: boolean = false;
  public importingContactsLength: number  = 0;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    if(this.business?.partner?.name?.toLowerCase() === 'effcode') this.isPartnerBusiness = true;
  }

  getMenuBarState() :boolean {
    console.log(this.responsiveMenuBarOpen);
    return this.responsiveMenuBarOpen;
  }

  getNotificationBarSize() {
    let height = 0;
    if(this.newVersionEnabled) height += 44;
    // if(this.remoteConfig.downtime) height += 35;
    // if(this.business.subscription.is_expired) height += 44;
    // if(!this.business.subscription.is_expired && this.subscriptionDaysLeft < 4) height += 44;
    return height;
  }
  emitChange(change: any) {
    this.routeChange.next(change);
  }

  setBusiness(business: any) {
    // if(!business?.subscription) {
    //   this.router.navigate(['/trial-setup']);
    // }
    this.business = new Business(business);
    console.log(this.business)
    let today = new Date()
    let endDate = new Date(this.business.subscription.end_date)
    this.subscriptionDaysLeft = Math.round(Math.abs(endDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000));
    if(!this.business.is_live) this.gettingStartedStepCount = 4;
    if(!this.business.is_agent_deployed) this.gettingStartedStepCount = 3;
    if(!this.business.is_agent_test) this.gettingStartedStepCount = 2;
    if(!this.business.is_agent_created) this.gettingStartedStepCount = 1;
  }

  setWhatsappDetails(details: any) {
    this.whatsappDetails = new WhatsappDetails(details);
    if(!this.whatsappDetails?.waba_id) this.isWabaEnabled = false;
  }

  getApiCountryList() {
    this.http.get<any>("https://api.countrystatecity.in/v1/countries", {
      headers: {
        "X-CSCAPI-KEY": 'dXdzemJJTXVCYkVZdFN6dXBiYzMyRFpLeVVjUGZwSG1PMzRrMTlWTg==',
      }
    }).subscribe({
      next: (result) => {
        this.apiCountryList = result;
      },
      error: (error: any) => {
        console.log('error', error);
      }
    })
  }

  getApiStateList(countryCode: string = '') {
    let url = 'https://api.countrystatecity.in/v1';
    if(countryCode === '') url = url + '/states';
    else url = url + "/countries/"+countryCode+"/states";
    this.http.get<any>(url, {
      headers: {
        "X-CSCAPI-KEY": 'dXdzemJJTXVCYkVZdFN6dXBiYzMyRFpLeVVjUGZwSG1PMzRrMTlWTg==',
      }
    }).subscribe({
      next: (result) => {
        this.apiStateList = result;
      },
      error: (error: any) => {
        console.log('error', error);
      }
    })
  }

  getApiCityList(countryCode: string,stateCode: string = '') {
    let url = 'https://api.countrystatecity.in/v1/countries/'+countryCode;
    if(stateCode === '') url = url + '/cities';
    else url = url + "/states/"+stateCode+"/cities";
    this.http.get<any>(url, {
      headers: {
        "X-CSCAPI-KEY": 'dXdzemJJTXVCYkVZdFN6dXBiYzMyRFpLeVVjUGZwSG1PMzRrMTlWTg==',
      }
    }).subscribe({
      next: (result) => {
        this.apiCityList = result;
      },
      error: (error: any) => {
        console.log('error', error);
      }
    })
  }

  setCloudNumbers(data: any) {
    localStorage.setItem('cloud-numbers',JSON.stringify(data))
    this.cloudNumbers = [];
    data?.forEach((element : any) => {
      this.cloudNumbers.push(new CloudNumber(element));
    })
  }
}
