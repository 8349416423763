import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { WebSocketService } from '../services/web-socket.service';
import { ToasterService } from '../services/toastr.service';
import { CommonService } from '../services/common.service';
import { SharedDataService } from '../services/shared-data.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToasterService,
    private websocketService: WebSocketService,
    private commonService: CommonService,
    private _sharedService: SharedDataService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          console.log('TOken expire');
          let refreshToken = localStorage.getItem('refresh');
          if (refreshToken) {
            let input = {
              refresh: refreshToken,
            };
            this.http.post<any>('/refresh/', input).subscribe(
              (resp) => {
                console.log(resp.access);
                let token = 'JWT ' + resp.access;
                localStorage.setItem('token', token);
              },
              (error) => {
                this.toastr.error(error.detail);
                this.commonService.logout();
              }
            );
          } else {
            if(localStorage.getItem('token')) this.commonService.logout();
          }
        } else if (err.status === 403) {
          if(err.error.message){
            console.log(err.error.message);
            this.toastr.error(err.error.message);
          } else if(err.error.detail){
            console.log(err.error.detail);
            this.toastr.error(err.error.detail);
          } else {
            console.log(err);
          }
        } else if (err.status === 500) {
          console.log(err);
          console.log(typeof(err));
          console.log(err.error)
        } else if(err.status === 412) {
          if(err.error.code === 1050) {
            if(this._sharedService.business?.subscription?.id) this.router.navigate(['/subscription-expired']);
            else this.router.navigate(['/onboarding-plans']);
          } else {
            this.router.navigate(['/onboarding']);
          }
          // if(err.error.code === 1050) {
          //   this.router.navigate(['/profile']);
          // } else if(err.error.code === 1052) {
          //   // localStorage.setItem('whatsapp',JSON.stringify(err.error.data))
          //   // this.router.navigate(['/connect-webhook']);
          // } else if(err.error.code === 1051){
          //   // this.websocketService.closeWebSocket();
          // } else {
          //   // this.commonService.logout()
          // }          
        } else if(err.status === 423) {
          this.commonService.logout();
        } else if(err.status === 424) {
          this.router.navigate(['/agency-expired']);
        } else {
          if(err.error.message){
            console.log(err.error.message);
            this.toastr.error(err.error.message);
          } else if(err.error.detail){
            console.log(err.error.detail);
            this.toastr.error(err.error.detail);
          } else {
            console.log(err);
          }
        }
        const error = err?.error?.message || err?.error?.error || err?.error?.detail;
        return throwError(error);
      })
    );
  }
}
